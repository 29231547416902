import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0b657376"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main-page"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_button = _resolveComponent("van-button");
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_router_view = _resolveComponent("router-view");
  const _component_van_action_sheet = _resolveComponent("van-action-sheet");
  return _openBlock(), _createElementBlock(_Fragment, null, [_withDirectives(_createVNode(_component_van_nav_bar, {
    title: $data.routeMeta.title,
    onClickLeft: $options.openLeftPopup
  }, {
    left: _withCtx(() => [_createVNode(_component_van_icon, {
      name: "wap-nav",
      size: "22"
    })]),
    title: _withCtx(() => [_createVNode(_component_van_button, {
      onClick: $options.openTeamSelectAction,
      class: "nav-title-btn",
      "icon-position": "right",
      type: "primary"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.getCurrTeam.team_name), 1), _createVNode(_component_van_icon, {
        name: "arrow-down"
      })]),
      _: 1
    }, 8, ["onClick"])]),
    right: _withCtx(() => []),
    _: 1
  }, 8, ["title", "onClickLeft"]), [[_vShow, $data.routeMeta.isNeedLogin]]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_router_view)]), _createVNode(_component_van_action_sheet, {
    show: $data.showTeamAction,
    "onUpdate:show": _cache[0] || (_cache[0] = $event => $data.showTeamAction = $event),
    actions: $options.teamList,
    "cancel-text": "取消",
    title: "切换团队",
    "close-on-click-action": "",
    onSelect: $options.onTeamSelect
  }, null, 8, ["show", "actions", "onSelect"])], 64);
}